<template>
  <v-card color="white" elevation="1" :class="cardPadding">

    <v-card-title>
      <v-icon v-if="cardTitleIcon" large left>{{ cardTitleIcon }}</v-icon>
      <span class="title font-weight-bold">{{ cardTitle }}</span>
    </v-card-title>

    <v-card-subtitle v-if="cardSubtitle">{{ cardSubtitle }}</v-card-subtitle>

    <v-divider class="mb-6"></v-divider>

    <slot></slot>

    <preview-dialog/>

  </v-card>
</template>

<script>
import PreviewDialog from "@/components/Dialogs/DialogPreview";

export default {
  name: "PageCard",
  components: {
    PreviewDialog
  },
  props: {
    cardPadding: {
      type: String,
      default: "pa-2",
    },
    cardTitle: {
      type: String,
      required: true
    },
    cardTitleIcon: {
      type: String,
    },
    cardSubtitle: {
      type: String,
    },
  },
  computed: {
    dialogPreview: {
      get: function () {
        return this.$store.state.dialogPreview
      },
      set(value) {
        this.$store.commit("setDialogPreview", value)
      }
    },
    previewImagesReady: {
      get: function () {
        return this.$store.state.previewImagesReady
      },
      set(value) {
        this.$store.commit("setPreviewImagesReady", value)
      }
    },
    previewImages() {
      return this.$store.state.previewImages
    }
  }
}
</script>
